@import "~@ng-select/ng-select/themes/default.theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.button {
  height: 40px;
  background: #FF9559;
  border-radius: 6px;
  margin-top: 3px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #FFFFFF;
  margin-left: 1rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.plus-icon {
  height: 17px !important;
  width: 17px !important;
}
.permission-name {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: capitalize;
  color: #065096;
}
.create-button {
  background: #4399FF;
  border-radius: 8px;
  color: #FFFFFF;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  height: 36px;
  min-width: 130px;
}
.title {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #3F3F3F;
  display: flex;
}

.title .title-icon {
  height: 26px;
  width: 26px;
}

.label {
  border-left: 7px solid #FF9559;
  border-radius: 5px;
}

.label .text {
  margin-left: 0.7rem;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #5A5A61;
}
.user-tab mat-tab-header .mat-tab-label {
  font-size: 16px;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin: 15px;
  flex-wrap: wrap;
}

.user-tab mat-tab-header .mat-tab-labels {
  background: #FFFFFF;
  border-radius: 5px;
  border: none;
}

.user-tab  mat-tab-header {
  border-bottom: 1px slategrey !important;
  margin-bottom: auto;
  margin-top: auto;
}
.user-tab mat-tab-header .mat-tab-list .mat-ink-bar {
  visibility: hidden !important;
}

.user-tab .mat-tab-label.mat-tab-label-active {
  background: #FFFFFF;
  color: #FF9559;
  border-bottom: 6px solid #FF9559;
  border-bottom-right-radius: 6px 6px;
  border-bottom-left-radius: 6px 6px;
}
.success-snackbar {
  background: rgb(13, 193, 81);
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #FFFFFF;
}
.error-snackbar {
  background: rgb(243, 108, 60);
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #FFFFFF;
}
.success-snackbar .mat-simple-snackbar .mat-simple-snackbar-action {
  color: white;
}
.error-snackbar .mat-simple-snackbar .mat-simple-snackbar-action {
  color: white;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  color:  #4F5B67;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.03em;
  font-size: 15px;
  margin-top: -14px;

}
.mat-select-value-text {
  color:  #4F5B67;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.03em;
  font-size: 14px;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  border-radius: 18px !important;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.2em) scale(0.75);
  width: 116.333333%;
  margin-left: 1px;
}
.modal-error {
  color:  orangered;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.03em;
  font-size: 10px;
}

ngb-pagination ::ng-deep .page-item .page-link {
  border: none;
  color: #000000;
}
ngb-pagination ::ng-deep .page-item:first-child .page-link  {
  color: #065096!important;
}
ngb-pagination ::ng-deep .page-item:last-child .page-link {
  color: #065096!important;
}
/*pagination*/
.paginator {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  background-color: transparent;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 1rem;
}

/*@media (max-width: 560px) {*/
/*  .paginator {*/
/*    flex-direction: column-reverse;*/
/*  }*/
/*}*/

ngb-pagination ::ng-deep .page-item.active .page-link {
  width: 32px !important;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  align-self: center;
  text-align: center;
  text-decoration: underline solid #366FB3 5px;
  background: none;
}

ngb-pagination ::ng-deep .page-item {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
  border-color: #FFFFFF;
}

.page-link:focus {
  box-shadow: none !important;
}

.pageSize {
  display: flex;
  flex-direction: row-reverse;
  height: 32px;

  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 5px;
}

.pageSizeSpan {
  margin: auto;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  align-self: center;
  text-align: center;
  color: #3F3F3F;
  padding: 5px;
}

.pageSizeSelect {
  align-self: center;
  float: right;
  background-color: transparent !important;
}

.pageSizeSelect .custom-select {
  width: 53px;
  height: 32px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #366FB3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 5px;
}

.custom-select:focus {
  box-shadow: none !important;
}
.options {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #3F3F3F;
  font-weight: 500;
}
.ng-select .ng-select-container {
  min-height: 41px;
  min-width: 280px;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 160px !important;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #FEF9F6;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #88a0f5;
  border-radius: 10px;
}

.dip-delete-btn{
  margin: 0 0 0 1% !important;
  background: #FF474D;
  color: white;
}






